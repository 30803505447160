module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"openGraph":{"type":"website","locale":"fr_FR","url":"https://lacomptadepapa.fr/","site_name":"La Compta de Papa"},"twitter":{"cardType":"summary_large_image"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"La Compta de Papa","short_name":"Compta de Papa","description":"Bienvenue sur le site de la Compta de Papa ! Découvrez nos missions, nos équipes et contactez nous pour plus d'informations.","lang":"fr","display":"standalone","icon":"src/assets/img/core-img/logo.png","start_url":"/","background_color":"#fff","theme_color":"#185b67","cache_busting_mode":"none","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-MEXEB7G56Q"],"pluginConfig":{"head":false,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"],"delayOnRouteUpdate":0}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
